import React, { useRef, useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import {
  Box,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
//summer video
import HomeVideo from '../../videos/trec-home-video.mp4'
//import HomeVideo from '../../videos/trec-winter.mp4'

const BackgroundFallback = () => (
  <Box position="absolute" top="0" left="0" w="100%" h="100%" zIndex="-1">
    {/* <StaticImage src="../../images/winter-fallback.jpg" placeholder='blurred'/> */}
    {/* Summer video */}
    <StaticImage src="../../images/home-fallback.jpg" placeholder='blurred'/> 
  </Box>
)


const PageHero = (props) => {
    
  const [playerState, setPlayerState] = useState({
    isPlaying: true,
  })

  const videoRef = useRef(null);
  
  const togglePlay = () => {
    setPlayerState({
      ...playerState,
      isPlaying: !playerState.isPlaying,
    });
    if (playerState.isPlaying) {
      videoRef.current.pause();
    } else {
    videoRef.current.play();
  }
}
  return (
    <>
      <Box className='background-video-container' display={{base: 'none', md: 'block'}} w="100%" position="relative">
        <BackgroundFallback />
         <video ref={videoRef} autoplay="true" loop="true" muted="true" className='homeVideo' style={{marginLeft: 'auto', marginRight: 'auto', width: '100vw', height: '75vh', background: 'transparent', objectFit: 'cover', backgroundPosition: 'top center'}}>
          <source src={HomeVideo} type="video/mp4" />
        </video>
        <div id="video-controls" class="controls" data-state="hidden" 
          style={{
              height: '30px',
              width: '30px',
              bottom: '5px',
              left: '49%',
              right: '50%',
              position: 'absolute',
              zIndex: '900'
            }}
          >
          <button id="playpause" type="button" data-state="play" 
            style={{
              background: 'none',
              border: 'none',
              cursor: 'pointer',
              width: '30px',
              height: '30px'
            }}
            onClick={togglePlay}
            >
              {!playerState.isPlaying ? (
              <>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                    <g id="Layer_1-2" data-name="Layer 1">
                      <path class="cls-1" fill='white' d="M29.92752,19.17647l-13.86676-8.006a1.10287,1.10287,0,0,0-1.6543.95511v16.012a1.10287,1.10287,0,0,0,1.6543.95511l13.86676-8.006A1.10287,1.10287,0,0,0,29.92752,19.17647Z"/>
                      <path class="cls-1"fill='white' d="M20,0A20,20,0,1,1,0,20,20,20,0,0,1,20,0Zm0,1.05263A18.94737,18.94737,0,1,0,38.94737,20,18.96884,18.96884,0,0,0,20,1.05263"/>
                    </g>
                </svg>
              </>) : (
                <>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <g id="Layer_1-2" data-name="Layer 1">
        <path class="cls-1" fill='white' d="M40,20A20,20,0,1,1,20,0,20,20,0,0,1,40,20ZM20,1.05263A18.94737,18.94737,0,1,0,38.94737,20,18.96884,18.96884,0,0,0,20,1.05263"/>
        <path class="cls-1" fill='white' d="M15,29.20205A1.3351,1.3351,0,0,1,13.66494,27.867V12.41584a1.33506,1.33506,0,1,1,2.67012,0V27.867A1.3351,1.3351,0,0,1,15,29.20205Z"/>
        <path class="cls-1" fill='white' d="M24.86842,29.31409A1.3351,1.3351,0,0,1,23.53336,27.979V12.52775a1.33506,1.33506,0,1,1,2.67012,0V27.979A1.3351,1.3351,0,0,1,24.86842,29.31409Z"/>
      </g>
  </svg>
                </>
              )}
          </button>
      </div>
      </Box>
      <Box display={{base: 'block', md: 'none'}}>
        <StaticImage src="../../images/winter-fallback.jpg" placeholder='blurred'/>
      </Box>
    </>
  )
}

const StyledBackgroundSection = styled(PageHero)`
    width: 100%;
    min-height: 60vh;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
`;

export default StyledBackgroundSection