import * as React from "react"
import { graphql } from "gatsby"
import Layout from '../components/layout'
import Seo from '../components/Seo'
import {
  Box,
  Heading,
  Container,
  Flex,
} from '@chakra-ui/react'
import { PrismicRichText } from "@prismicio/react"

import Hero from '../components/Hero/Hero'
import CtaButton from '../components/CtaButton/CtaButton'

import SliceZone from "../components/SliceZone/SliceZone"
import SeasonsCards from "../components/SeasonsCards/SeasonsCards"

import SupportersSection from "../components/SupporterSection/SupporterSection"
import NewsCards from "../components/NewsCard/NewsCards"


const IndexPage = ({ data, location }) => {
  const document = data.prismicHomepage.data;
  const newsItems = data.allPrismicNewsAndEvents.edges;
  const pageLocation = location.pathname;

  return (
    <Layout pageLocation={pageLocation}>
      <Seo title="Discover Teton Valley" />
      <Hero 
        heroImage={document.homepage_hero_image.localFile}
        heroImageAlt={document.homepage_hero_image.alt}
      />
      <Box h={{base: 2, lg: 4}} bg="sage.500" />
      <Heading as="h1" variant="h1" textAlign="center" mt="8" mb="4" px="4" color="sage.700">
        {document.homepage_headline.text}
      </Heading>

      <Container maxW="container.xl" textAlign="center">
        <PrismicRichText field={document.homepage_content.richText} />
      </Container>
            
      <SeasonsCards />
  
      <Container maxW="container.lg" my="8">
      {document.homepage_cta_buttons && (
          <Flex w="100%" justifyContent={{base: "center", lg: "space-around"}} flexWrap="wrap">
            {document.homepage_cta_buttons.map((btn, index) => (
              <Box key={index} p="2">
                <CtaButton
                  variant="Blue"
                  size="lg"
                  linkTo={btn.button_link.url}
                  buttonLabel={btn.button_label}
              />
              </Box>
            ))}
          </Flex>
        )}
        
      </Container>
      
      <SliceZone sliceZone={document.body} />
      
      <Heading as="h2" variant="h2" mt="12" mb="4" textAlign="center" color="sage.700" fontSize="clamp(2rem, 6vw, 3rem)">
                TREC &amp; Geo News
            </Heading>
      <NewsCards 
        newsItem={newsItems}
      />

      <SupportersSection />
    
    </Layout>
  )
}

export const query = graphql`
{
  prismicHomepage {
    data {
      homepage_hero_image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              outputPixelDensities: 1.5
              placeholder: BLURRED
            )
          }
        }
        alt
      }
      homepage_headline {
        text
      }
      homepage_content {
        richText
      }
      homepage_cta_buttons {
        button_label
        button_link {
          url
          target
        }
      }
      body {
        ... on PrismicHomepageDataBodyBlueBoxSection {
          id
          slice_type
          primary {
            section_title {
              text
            }
          }
          items {
            label_4
            label_3
            label_2
            label_1
            link_4 {
              url
            }
            link_3 {
              url
            }
            link_2 {
              url
            }
            link_1 {
              url
            }
            image_4 {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1
                    layout: FULL_WIDTH
                    transformOptions: {cropFocus: CENTER}
                  )
                }
              }
            }
            image_3 {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1
                    layout: FULL_WIDTH
                    transformOptions: {cropFocus: CENTER}
                  )
                }
              }
            }
            image_2 {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1
                    layout: FULL_WIDTH
                    transformOptions: {cropFocus: CENTER}
                  )
                }
              }
            }
            image_1 {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1
                    layout: FULL_WIDTH
                    transformOptions: {cropFocus: CENTER}
                  )
                }
              }
            }
          }
        }
        ... on PrismicHomepageDataBodyLogoCallToAction {
          id
          slice_type
          primary {
            logo {
              alt
              localFile {
                publicURL
              }
            }
            align
            content {
              richText
              text
            }
            button_link {
              url
              target
            }
            button_label
            text_area_text_color
            text_area_background_color
            image_area_background_color
            full_width_image
            button_solid_or_outline
          }
        }
        ... on PrismicHomepageDataBodyFullImageSection {
          id
          slice_type
          primary {
            text_content {
              richText
            }
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, quality: 8, layout: FULL_WIDTH)
                }
              }
              alt
              copyright
            }
            headline {
              text
            }
            button_link {
              url
              target
            }
            button_label
            button_color
          }
        }
        ... on PrismicHomepageDataBodyNewsCarousel {
          id
          slice_type
          items {
            title
            excerpt
            button_link {
              url
              target
            }
            button_label
            image {
              alt
              copyright
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    quality: 8
                 )
                }
              }
            }
          }
        }
        ... on PrismicHomepageDataBodyAdvertisement {
          id
          slice_type
          primary {
            ad_link {
              url
              target
            }
            mobile_ad_image {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 80)
                }
              }
            }
            desktop_ad_image {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 80)
                }
              }
            }
          }
        }
      }
    }
  }
  allPrismicNewsAndEvents(
    limit: 3
    sort: {order: DESC, fields: last_publication_date}
  ) {
    edges {
      node {
        data {
          title {
            text
          }
          featured_image {
            alt
            localFile {
              childImageSharp {
                gatsbyImageData(
                  aspectRatio: 1.5
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  transformOptions: {cropFocus: CENTER}
                )
              }
            }
          }
          exceprt
          event_date(formatString: "MMMM DD, YYYY")
          content {
            richText
          }
        }
        uid
      }
    }
  }
}

`


export default IndexPage;
