import React from "react";
import { motion } from "framer-motion";
import { StaticImage } from "gatsby-plugin-image";
import { Link as GatsbyLink } from 'gatsby'
import {
  Box,
  Heading,
  LinkOverlay,
  LinkBox,
} from '@chakra-ui/react'

const Fall = () => {

  return (
    <LinkBox position="relative" w={{base: '100%', md: '50%', lg: '25%'}} p="2" as={motion.div} whileHover={{ scale: 1.05 }}  whileTap={{ scale: 0.95 }}>
      <LinkOverlay as={GatsbyLink} to="/discover/fall">
      <Box boxShadow="xl" borderRadius="xl">
        <StaticImage src='../../../images/fall@3x.png' alt="Fall Illustration" imgStyle={{borderRadius: '1rem'}}/>
      </Box>
      <Box position="absolute" bottom="3rem" w="100%">
        <Heading as="h3" color="white" fontSize="6xl" textAlign="center" mb="12">
            Fall
        </Heading>
      </Box>
      </LinkOverlay>
    </LinkBox>
  );
};

export default Fall;
