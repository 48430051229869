import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import {
    Container,
    Flex,
    Box
} from '@chakra-ui/react';

import Winter from './svg/Winter';
import Spring from './svg/Spring';
import Summer from './svg/Summer';
import Fall from './svg/Fall';

export default function SeasonsCards() {

    return (
        <Container maxWidth="container.xl" my="16">
            <Flex flexWrap="wrap" justifyContent={{base: 'center', md: 'space-between'}}>
              {/* <Box w="25%" p="2"><StaticImage src='../../images/winter-card.jpg' alt="Winter Illustration" imgStyle={{borderRadius: '1rem'}}/></Box>
              <Box w="25%" p="2"><StaticImage src='../../images/spring-card.jpg' alt="Spring Illustration"  imgStyle={{borderRadius: '1rem'}}/></Box>
              <Box w="25%" p="2"><StaticImage src='../../images/summer-card.jpg' alt="Summer Illustration"  imgStyle={{borderRadius: '1rem'}}/></Box>
              <Box w="25%" p="2"><StaticImage src='../../images/fall-card.jpg' alt="Fall Illustration"  imgStyle={{borderRadius: '1rem'}}/></Box> */}
                <Winter />
                <Spring />
                <Summer />
                <Fall />
            </Flex>
        </Container>
    );
}