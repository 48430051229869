import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Box, Link, Flex, Center, Heading, Container } from "@chakra-ui/react";

export default function SupportersSection() {
  return (
    <Container maxWidth="container.xl" as="section" my="12">
    <Heading as="h1" variant="h1" textAlign="center" color="sage.700">
          Sponsors
        </Heading>
        <Flex
          w="100%"
          justifyContent="center"
          alignItems="center"
          h="100%"
          mb="12"
          flexWrap="wrap"
        >
          <Box w={{base: "50%", md: "20%" }} p="4">
          <Link href="https://commerce.idaho.gov/" target="_blank" rel="noopener noreferrer">
            <Center>
              <StaticImage
                src="./logos/Idaho-Commerce.svg"
                alt="Idaho Commerce Logo"
              />
            </Center>
            </Link>
          </Box>

          <Box w={{base: "50%", md: "20%" }} p="4">
          <Link href="https://www.tetoncountyidaho.gov" target="_blank" rel="noopener noreferrer">
            <Center>
              <StaticImage
                src="./logos/Teton-County-Logo.jpg"
                alt="Teton County Idaho Logo"
              />
            </Center>
            </Link>
          </Box>
          
          <Box w={{base: "50%", md: "20%" }} p="4">
            <Link href="https://victorcityidaho.com" target="_blank" rel="noopener noreferrer">
              <Center>
                <StaticImage
                  src="./logos/city-of-victor-logo.jpg"
                  alt="City of Victor Logo"
                  imgStyle={{ maxWidth: "158px" }}
                  objectFit="contain"
                />
              </Center>
            </Link>
          </Box>
          <Box w={{base: "50%", md: "20%" }} p="4">
            <Link href="https://driggsidaho.org" target="_blank" rel="noopener noreferrer">
              <Center>
                <StaticImage
                  src="./logos/City-of-Driggs_Blue-Seal-Logo.svg"
                  alt="City of Driggs Blue Seal Logo"
                  imgStyle={{ maxWidth: "158px" }}
                  objectFit="contain"
                />
              </Center>
            </Link>
          </Box>
          <Box w={{base: "50%", md: "20%" }} p="4">
          <Link href="https://tetoniaidaho.org" target="_blank" rel="noopener noreferrer">
            <Center>
              <StaticImage
                src="./logos/City-of-Tetonia.jpg"
                alt="Tetonia Logo"
                imgStyle={{ maxWidth: "158px" }}
                objectFit="contain"
              />
            </Center>
            </Link>
          </Box>
         
          <Box w={{base: "50%", md: "20%" }} p="4">
            <Link href="https://visitidaho.org" target="_blank" rel="noopener noreferrer">
            <Center>
              <StaticImage
                src="./logos/Visit-Idaho-Logo.svg"
                alt="Visit Idaho Logo"
              />
            </Center>
            </Link>
          </Box>
        </Flex>
        <Heading as="h1" variant="h1" textAlign="center" color="sage.700">
          Partners
        </Heading>
        <Flex
          w={{base: '100%', md: '60%'}}
          justifyContent="center"
          alignItems="center"
          h="100%"
          mx="auto"
          flexWrap="wrap"
        >
          <Box w={{base: "50%", md: "25%" }} p="4">
          <Link href="https://www.tetoneducation.org" target="_blank" rel="noopener noreferrer">
            <Center>
              <StaticImage
                src="./logos/Edu-Foundation.jpg"
                alt="Education Foundation Logo"
              />
            </Center>
            </Link>
          </Box>
          <Box w={{base: "50%", md: "25%" }} p="4">
            <Link href="https://cftetonvalley.org" target="_blank" rel="noopener noreferrer">
            <Center>
              <StaticImage
                src="./logos/community-foundation-logo.svg"
                alt="Community Foundation Logo"
              />
            </Center>
            </Link>
            </Box>
            <Box w={{base: "50%", md: "25%" }} p="4">
            <Link href="https://www.downtowndriggs.org" target="_blank" rel="noopener noreferrer">
            <Center>
              <StaticImage
                src="./logos/DDA-Logo-2018.png"
                alt="Downtown Driggs Assocation logo" />
            </Center>
            </Link>
          </Box>
          <Box w={{base: "50%", md: "25%" }} p="4">
            <Link href="https://yellowstoneteton.org" target="_blank" rel="noopener noreferrer">
            <Center>
              <StaticImage
                src="./logos/YTT-logo-retina.png"
                alt="Yellowstone Teton Territory logo" />
            </Center>
            </Link>
          </Box>
          </Flex>
    </Container>
  );
}
